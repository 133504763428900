import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import BannerImage from '../components/BannerImage/BannerImage'

const About = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[
          `fivelde`,
          `eco`,
          `edirectory`,
          `renewable`,
          `ethical`,
          `directory`,
          `building`,
          `fairtrade`,
          `supplies`,
        ]}
      />
      <BannerImage />
      <Container>
        <ContainerItem>
          <Heading>Fivelde</Heading>
          <p>
            We're kinda rad, our site is cool, it does things. Sometimes good
            things.{' '}
          </p>
        </ContainerItem>
        <ContainerItem>
          <Heading>Our Values</Heading>
          <p>We want to encourage the use of sustainble materials</p>
        </ContainerItem>
        <ContainerItem>
          <Heading>Our Motivations</Heading>
          <p>To lessen the impact we're leaving on the planet</p>
        </ContainerItem>
        <ContainerItem>
          <Heading>Our Customers</Heading>
          <p>
            We aim to only represent those that play by Scout rules; leave the
            world a little better than you found it
          </p>
        </ContainerItem>
        <ContainerItem>
          <Heading>Tommy B</Heading>
          <p>
            A Playboy Millionaire, he likes the V, and that P
            <span role="img" aria-label="money bags emoji">
              💰
            </span>
          </p>
        </ContainerItem>
        <ContainerItem>
          <Heading>Rico Karoulla</Heading>
          <p>
            One bad motherfucker, he'll fuck up any jive turkey who cross him
          </p>
        </ContainerItem>
      </Container>
    </Layout>
  )
}

export default About

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 900px;
  margin: auto;
  padding: 0 10px;
`

const ContainerItem = styled.div`
  text-align: center;
  padding: 40px 0;
`

const Heading = styled.h1`
  font-size: 24px;
  padding: 16px 0;
`
